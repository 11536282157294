.ProgressBarMarker {
  float: left;
  position: absolute;
  height: 8px;
  padding: 8px 8px 0 8px;
  z-index: 6;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.ProgressBarMarkerPointer,
.ProgressBarMarkerPointerGrey,
.ProgressBarMarkerPointerHighlighted {
  height: 10px;
  width: 2px;
  background-color: #0047FF;
}

.ProgressBarMarkerPointerHighlighted {
  background-color: #ffb800;
}

.ProgressBarMarkerPointerExtensionHighlighted > svg > path {
  fill: #ffb800;
}

.ProgressBarMarkerPointerGrey {
  background-color: #bcbcbc;
}

.ProgressBarMarkerPointerExtensionGrey > svg > path {
  fill: #bcbcbc;
}

.ProgressBarMarkerPointerExtension,
.ProgressBarMarkerPointerExtensionGrey,
.ProgressBarMarkerPointerHighlighted {
  position: relative;
  top: -15px;
  display: inline-block;
}

.HoverContainer {
  position: absolute;
  display: inline-block;
  width: max-content;
  z-index: 3;
  top: -14px;
}

.ProgressBarMarkerName {
  display: inline-block;
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 15px;
  margin-right: 6px;
  top: -6px;
  float: left;
  cursor: default;
  height: 24px;
  line-height: 24px;
  z-index: 4;

  border-radius: 4px;
  background-color: rgba(251, 252, 255, 1);
  backdrop-filter: blur(10px);
}

.Delete {
  background-color: #E9E9E9;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  display: inline-flex;
  float: left;
  align-items: center;
  justify-content: center;

  position: relative;
  top: -6px;
  z-index: 4;
}

.Play {
  background-color: #E9E9E9;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  display: inline-flex;
  float: left;
  align-items: center;
  justify-content: center;

  position: relative;
  top: -6px;
  z-index: 4;
}

.Stop {
  background-color: #E9E9E9;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  display: inline-flex;
  float: left;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  position: relative;
  top: -6px;
  z-index: 4;
}
