.SearchApp {
  width: 100%;
  left: -10px;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.SearchBarContainer {
  display: flex;
  justify-content: center;
}

.SearchBar {
  width: 100%;
  height: 84px;

  margin: 0;
  padding: 0;

  border: 2px solid #0047FF;
  border-radius: 44px;

  background-color: #FFF;

  font-size: 20px;
  outline: none;

  position: relative;

  font-family: "Raleway";
  font-weight: 500;

  padding-left: 32px;
  padding-right: 96px;
  z-index: 4;
}

.Upload {
  height: 70px;
  min-width: 70px;
  border-radius: 35px;

  background-color: #FFF;
  cursor: pointer;

  padding: 0;
  border: 2px;

  position: absolute;
  right: 26px;  /* ? */
  top: 9px;  /* yes */

  z-index: 5;
}

.SearchResultsContainer {
  width: 100%;
  overflow-y: scroll;

  top: -10px;
  position: relative;
  padding-top: 10px;

  height: calc(100vh - 297px);
}

.SearchResultsContainer::-webkit-scrollbar {
  display: none;
}

.ServiceCheckboxContainer {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.ServiceCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 24px;

  height: 52px;
  width: 52px;
  border: 2px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 4px 4px 12px rgb(0 0 0 / 15%);

  background-color: #FFF;

  cursor: pointer;
  user-select: none;
}

.ServiceCheckboxLogoContainer {
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchResults {
  margin-top: 36px;
  padding-bottom: 54px;
}

.SearchResults::-webkit-scrollbar {
  display: none;
}
