.BottomBar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 112px;
  z-index: 3;

  background-color: #FFF;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ProgressBarContainer {
  position: absolute;
  top:  -2px;
  width: 100%;
  height: 2px;
  background-color: #bcbcbc;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Playback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Playback > button {
  height: 72px;
  margin: 0px 16px;
  background-color: inherit;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Playback > button:disabled > svg > path {
  stroke: #BCBCBC;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Settings {
  position: absolute;
  height: 24px;
  left: 81.5px;
  bottom: 43px;
}

.Settings > button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Volume {
  width: 65px;
  height: 65px;
  float: right;
  background-color: rgba(0,0,0,0);
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 61px;
  bottom: 18.5px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.IconContainer {
  height: 24px;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/**********/
/* CSS.GG */
.ggSpinner {
    transform: scale(var(--ggs,1))
}
.ggSpinner,
.ggSpinner::after,
.ggSpinner::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 65px;
    height: 65px
}
.ggSpinner::after,
.ggSpinner::before {
    content: "";
    position: absolute;
    border-radius: 100px
}
.ggSpinner::before {
    animation: spinner 1s
    cubic-bezier(.6,0,.4,1) infinite;
    border: 2px solid transparent;
    border-top-color: #0047FF;
}
.ggSpinner::after {
    border: 2px solid #BCBCBC;
    opacity: 0.5;
    z-index: -1;
}
@keyframes spinner {
    0% { transform: rotate(0deg) }
    to { transform: rotate(359deg) }
}
