.QueueTrackHead {
  height: 84px;
  box-shadow: inset 0px 0px 0px 1px #bcbcbc;
  display: inline-flex;
  align-items: center;
  width: calc(100% - 48px);
  margin-bottom: 14px;
  margin-left: 24px;
  background-color: #FFF;
}

.QueueTrackHead:first-child {
  margin-top: 36px;
}

.QueueTrackChild {
  height: 66px;
  display: block;
  width: calc(100% - 100px);
  padding-left: 100px;
  background-color: inherit;
}

.QueueTrackChild:first-child {
  margin-top: 4px;
}

.QueueTrackChild:last-child {
  padding-bottom: 8px;
}

/* ALBUM ART */
.AlbumArtContainer {
  width: 74px;
  height: 48px;

  display: inline-block;
  float: left;
  margin-left: 26px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.AlbumArtContainer > img {
  max-height: 48px;
  max-width: 74px;
}

/* TRACK INFO */
.QueueInformationHead,
.QueueInformationChild {
  display: inline-block;
  float: left;
  height: 48px;
  margin-left: 34px;
  width: calc(100% - 366px);
}

.QueueInformationChild {
  margin-left: 22px;
  width: calc(100% - 262px);
}

.QueueInformationHead > h5,
.QueueInformationChild > h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.QueueInformationChild > h5 {
  margin-top: 12px;
}

.QueueInformationHead > h6,
.QueueInformationChild > h6 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.TrackInfoDuration {
  display: inline-block;
  float: right;
  font-size: 16px;
  margin-right: 50px;
}

.ButtonContainer {
  display: inline-block;
  float: right;
  margin-right: 18px;
}

.ButtonContainer > button {
  background-color: #FFF;
  border: 0;
  padding: 0;
  height: 48px;
  width: 48px;
  border-radius: 24px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.ButtonHead {
  padding: 6px;
  margin: 18px;
  margin-left: 18px;
  margin-right: 0px;
}

.ButtonChild {
  margin-left: 18px;
  margin-right: 0px;
}

.DurationContainerChild {
  margin-left: auto;
  margin-right: auto;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #BCBCBC;

  display: inline-block;
  float: left;
  cursor: default;
  height: 48px;
  line-height: 48px;
  margin-left: 30px;
}

.DurationContainerHead {
  margin-left: auto;
  margin-right: auto;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #BCBCBC;

  display: inline-block;
  cursor: default;
  float: right;
}


/* COLORS */
/* .spotify {
  border: 1px solid rgba(29, 185, 84, 1);
}

.apple_music {
  border: 1px solid rgba(251, 92, 116, 1);
}

.youtube {
  border: 1px solid rgba(255, 0, 0, 1);
}

.audius {
  border: 1px solid rgba(126, 27, 204, 1);
}

.jukebox_radio {
  border: 1px solid rgba(0, 71, 255, 1);
} */
