.QueueCollection {
  box-shadow: inset 0px 0px 0px 1px #bcbcbc;
  width: calc(100% - 48px);
  margin-bottom: 14px;
  margin-left: 24px;
  background-color: #FFF;
}

.QueueCollection:first-child {
  margin-top: 14px;
}

.QueueCollectionMain {
  width: 100%;
  height: 84px;
  display: inline-flex;
  align-items: center;
}

.AlbumArtContainer {
  width: 62px;
  height: 48px;

  display: inline-block;
  float: left;
  margin-left: 26px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: inherit;
  /* border-radius: 6px; */
}

.AlbumArtContainer > img {
  max-height: 48px;
  max-width: 64px;
}

.QueueInformation {
  display: inline-block;
  float: left;
  height: 48px;
  margin-left: 34px;
  margin-top: 2px;
  width: calc(100% - 362px);
}

.QueueInformation > h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.QueueInformation > h6 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.ButtonContainer {
  display: inline-block;
  float: right;
  margin-right: 18px;
}

.ButtonContainer > button {
  background-color: #FFF;
  border: 0;
  padding: 0;
  height: 48px;
  width: 48px;
  border-radius: 24px;

  margin-left: 18px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.DurationContainer {
  margin-left: auto;
  margin-right: auto;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #BCBCBC;

  display: inline-block;
  float: right;
  cursor: default;
}

/* COLORS */
/* .spotify {
  border: 1px solid rgba(29, 185, 84, 1);
}

.apple_music {
  border: 1px solid rgba(251, 92, 116, 1);
}

.youtube {
  border: 1px solid rgba(255, 0, 0, 1);
}

.audius {
  border: 1px solid rgba(126, 27, 204, 1);
}

.jukebox_radio {
  border: 1px solid rgba(0, 71, 255, 1);
} */
