.SearchResult {
  height: 84px;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px 1px #bcbcbc;
  margin-bottom: 14px;
  display: inline-flex;
  align-items: center;
  width: calc(100% - 48px);
  margin-left: 24px;

  background-color: #FFF;
}

/* .SearchResult:last-child {
  margin-bottom: 36px;
} */

.SearchResultSubmitted {
  height: 84px;
  /* background-color: rgba(251, 252, 255, 0.93);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  background-color: #FFF;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px 2.5px #0047FF;
  margin-bottom: 14px;
  display: inline-flex;
  align-items: center;
  width: calc(100% - 48px);
  margin-left: 24px;

  transition-property: box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.ImageParent {
  width: 62px;
  height: 46px;

  display: inline-block;
  float: left;
  margin-left: 26px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  /* border-radius: 6px; */
}

.ImageParent > img {
  max-height: 48px;
  max-width: 64px;
}

.ResultInformation {
  display: inline-block;
  float: left;
  height: 48px;
  margin-left: 32px;
  width: calc(100% - 315px);
}

.ResultInformation > h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-top: 1px;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.ResultInformation > h6 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.ServiceLogo {
  width: 24px;
  height: 24px;
  margin-right: 28px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: #FFF;
  border-radius: 36px;
}

.AddButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 83px;
  height: 48px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0047FF;

  background-color: #FFF;
  border: 2px solid #0047FF;
  border-radius: 24px;

  margin-right: 22px;
  cursor: pointer;

  transition-property: color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.AddButtonInverted {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 83px;
  height: 48px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;

  background-color: #0047FF;
  border: 2px solid #0047FF;
  border-radius: 24px;

  margin-right: 22px;
  cursor: not-allowed;

  transition-property: color, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

/**********/
/* CSS.GG */
.ggSpinner {
    transform: scale(var(--ggs,1))
}
.ggSpinner,
.ggSpinner::after,
.ggSpinner::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px
}
.ggSpinner::after,
.ggSpinner::before {
    content: "";
    position: absolute;
    border-radius: 100px
}
.ggSpinner::before {
    animation: spinner 1s
    cubic-bezier(.6,0,.4,1) infinite;
    border: 2px solid transparent;
    border-top-color: #0047FF;
}
.ggSpinner::after {
    border: 2px solid #EAEAEA;
    opacity: 0.5;
    z-index: -1;
}
@keyframes spinner {
    0% { transform: rotate(0deg) }
    to { transform: rotate(359deg) }
}

/* COLORS */
/* .spotify {
  border: 1px solid rgba(29, 185, 84, 1);
}

.apple_music {
  border: 1px solid rgba(251, 92, 116, 1);
}

.youtube {
  border: 1px solid rgba(255, 0, 0, 1);
}

.audius {
  border: 1px solid rgba(126, 27, 204, 1);
}

.jukebox_radio {
  border: 1px solid rgba(0, 71, 255, 1);
} */
