.YouTubeContainer > div,
.AppleMusicContainer > div {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #FFF;
}

.YouTubeContainer > div > iframe,
.AppleMusicContainer > div > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
