.ABCNotationDisplayContainer {
  width: 512px;
  position: relative;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ABCNotationDisplay {
  width: 355px;
  min-height: 62px;
  background-color: #FFF;
  border-radius: 6px;
  padding: 22px 28px;
  margin-bottom: 16px;
}

.ABCNotationDisplayDeleted {
  width: 413px;
  min-height: 48px;

  border-radius: 24px;
  margin-bottom: 19px;

  text-align: center;
  line-height: 48px;
  color: #DFE5F8;
  font-weight: 500;

  background-color: #5A80F7;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.HoverBuffer {
  height: 68px;
  width: 99px;
  content  : "";
  position : absolute;
  right: 0;
  top: 0;
  z-index: -1;
  display: block;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.HoverContainer {
  position: absolute;
  left: calc(100% - 77px);
  top: 0;
  margin-top: 22px;
  margin-right: -99px;
  width: 62px
}

.HoverContainer > button {
  margin: 0;
  border: 0;
  padding: 0;

  background-color: #E9E9E9;
  cursor: pointer;

  width: 24px;
  height: 24px;
  margin-right: 7px;
  border-radius: 2px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
