.TextCommentContainer {
  width: 512px;
  position: relative;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.TextComment {
  width: 357px;
  min-height: 62px;

  border-radius: 6px;
  padding: 22px 28px;
  margin-bottom: 16px;

  background-color: rgba(231, 249, 255, 1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.TextCommentDeleted {
  width: 413px;
  min-height: 48px;

  border-radius: 24px;
  margin-bottom: 19px;

  text-align: center;
  line-height: 48px;
  color: #DFE5F8;
  font-weight: 500;

  background-color: #5A80F7;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.HoverBuffer {
  height: 68px;
  width: 99px;
  content  : "";
  position : absolute;
  right: 0;
  top: 0;
  z-index: -1;
  display: block;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.HoverContainer {
  position: absolute;
  left: 100%;
  top: 0;
  padding: 22px;
  width: 62px;
  height: calc(100% - 44px);
}

.HoverContainer > button {
  margin: 0;
  border: 0;
  padding: 0;

  background-color: #E9E9E9;
  cursor: pointer;

  width: 24px;
  height: 24px;
  margin-right: 7px;
  border-radius: 2px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.HoverContainer > button:nth-child(3) {
  position: absolute;
  bottom: 22px;
  left: 22px;
}
