.SystemActionContainer {
  width: 512px;
  position: relative;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.SystemAction {
  width: 413px;
  margin-bottom: 16px;

  border-radius: 24px;

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: #DFE5F8;
}

.SystemAction > p {
  margin: 0;

  text-align: center;
  color: #bcbcbc;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
}
