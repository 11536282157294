.CloseModal {
  margin-top: 29px;
  margin-left: 29px;
  background-color: #FFF;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* TABS */
.TabButtonContainer {
  margin-top: 50px;
}

.TabButton {
  width: 206px;
  height: 35px;
  border: 2px solid #0047FF;
  border-radius: 35px;
  color: #0047FF;
  font-size: 18px;
  font-weight: bold;
  margin-right: 25px;
  background-color: #FFF;
  font-family: Raleway;
  cursor: pointer;
}

.TabButtonActive {
  width: 206px;
  height: 35px;
  border: 2px solid #0047FF;
  border-radius: 35px;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  margin-right: 25px;
  background-color: #0047FF;
  font-family: Raleway;
  cursor: pointer;
}

/* MOTIVE */

.MotiveContainer {
  margin-top: 30px;
  margin-left: 4px;
}

.MotiveCheckboxContainer {
  height: 18px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  margin-left: 12px;
  margin-right: 16px;
}

.MotiveCheckboxContainer > button {
  background: none;
  border: none;
  height: 18px;
  padding: 0;
  cursor: pointer;
}

.MotiveCheckboxContainer > span {
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  line-height: 20px;
  display: inline-block;
  float: right;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* MARKER FORM */
.Form {
  margin-top: 42px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.FormLabel {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.FormLabel > span {
  width: 145px;
  display: inline-block;
}

.FormInput {
  margin-left: 16px;
  border: 0;
  border-bottom: 1px solid #0047FF;
  outline: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  width: 260px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* INTERVAL FORM */

.FormSelect {
  margin-left: 16px;
  border: 0;
  border-bottom: 1px solid #0047FF;
  padding-right: 6px;
  background-color: #FFF;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  width: 260px;
}

.FormSubmit {
  width: 86px;
  height: 25px;

  border: 1px solid #0047FF;
  background-color: #FFF;
  border-radius: 25px;
  color: #0047FF;
  margin-top: 24px;
  margin-left: 0;
  margin-right: auto;

  cursor: pointer;

  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.ModalContent {
  margin: 88px;
  margin-top: 100px;
  height: 346px;
  width: calc(100% - 176px);
}

.QueueName {
  font-size: 22px;
  padding-left: 8px;
}

.ProgressBar {
  width: calc(100% - 176px);
  position: absolute;
  bottom: 88px;
}

.StemCheckbox {
  height: 52px;
  width: 52px;
  border: 2px;
  border-color: #fff;
  border-radius: 35px;
  box-shadow: 4px 4px 12px rgb(0 0 0 / 15%);
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
}
