.Session {
  height: 100%;
  width: 100%;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Logo {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 43px;
  top: 38px;
  z-index: 1064;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Content {
  position: relative;
  top: 0px;
  margin-left: 264px;
  height: 100%;
  width: calc(100% - 264px);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.MainContent {
  margin-left: 77px;
  margin-right: 77px;
  height: calc(100% - 104px);
  width: calc(100% - 149px);
  position: relative;
  float: left;
  margin-top: 95px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.PrimaryContent {
  height: calc(100% - 161px);
  width: calc(100% - 511px);  /* 67 + 67 + 300 + 57 */

  position: relative;
  float: left;
  margin-left: 77px;
  margin-top: 95px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.SecondaryContent {
  margin-left: 77px;
  margin-right: 57px;
  min-width: 300px;
  max-width: 300px;

  position: relative;
  float: left;
  display: block;
  top: 95px;
  box-sizing: border-box;
  max-height: calc(100% - 137px);
}
