.WelcomeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Roboto', sans-serif;

  padding: 66px 88px;
  border-radius: 44px;
}

.Logo {
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  left: 43px;
  top: 38px;
}

.WelcomeHeader {
  display: block;

  width: 985px;
  height: 105px;
  padding: 42px;
  margin-bottom: 26px;

  text-align: center;
}

.WelcomeHeader > h2 {
  font-weight: bold;
  font-size: 40px;
  margin: 0;
}

.WelcomeHeader > h5 {
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  margin-top: 34px;
}

.PlaybackOptionContainer {
  display: block;
  height: 152px;
}

.PlaybackOption {
  max-width: 137px;
  box-shadow: 4px 4px 12px rgb(0 0 0 / 15%);
  border-radius: 35px;

  display: inline-block;
  margin: 0 26px 0 0;

  padding: 26px;

  background-color: rgba(251, 252, 255, 0.93);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  cursor: pointer;
  position: relative;

  transition-property: color, background-color, filter;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}

.PlaybackOption:last-child {
  margin-right: 0px;
}

.PlaybackOption:hover {
  background-color: #FFF;
}

.PlaybackOptionLogo {
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 50%;
}

.PlaybackOptionService {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 8px 0 8px;
}

.PlaybackOptionDescription {
  font-size: 12px;
  font-weight: normal;
  margin: 8px 8px 8px 8px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* MODAL */
.ModalOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);

  display: flex;
  justify-content: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.Modal {
  outline: none;
}
