.FeedApp {
  width: 100%;
  height: 100%;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ContentContainer {
  display: inline-block;

  width: calc(100% - 723px);

  margin-left: 77px;
  margin-right: 57px;
  top: 95px;
  bottom: 170px;
  position: absolute;

  background-color: #FFF;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);

  border-radius: 44px;
}

.ContentContainer > h5 {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;

  margin: 0;
  color: black;

  position: absolute;
  bottom: 84px;
  left: 44px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 88px);
}

.ContentContainer > h6 {
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  margin: 0;
  color: black;

  position: absolute;
  bottom: 40px;
  left: 44px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 88px);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ImageContainer {
  padding: 44px;
  width: calc(100% - 88px);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 100px;
  top: 0px;
}

.ImageContainer > img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.FeedWrapper {
  display: inline-block;
  width: 512px;
  height: calc(100% - 170px);

  margin-right: 77px;
  margin-bottom: 170px;
  float: right;
}

.Feed {
  position: absolute;
  bottom: 228px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ComposeBar {
  position: absolute;
  bottom: 170px;
  height: 42px;
}

.ComposeBar > input {
  width: 320px;
  height: 42px;
  padding-left: 20px;

  border: 0;
  box-sizing: border-box;
  border-radius: 26.5px;

  outline: none;

  background-color: #FFF;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.ComposeBar > button {
  padding: 0;
  margin: 0;
  border: 0;

  margin-right: 16px;
  width: 30px;
  height: 30px;

  border-radius: 15px;
  box-sizing: border-box;

  background-color: #FFF;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  float: left;
  margin-top: 6px;
}

.ComposeBar > input:disabled,
.ComposeBar > button:disabled {
  cursor: not-allowed;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.NotationButton > div {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #C4C4C4;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.RecordButton > div {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #FF0000;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.SubmitButton {
  display: none!important;
}

/* COLORS */
.spotify {
  /* background-color: rgba(29, 185, 84, 0.5);
  box-shadow: 0px 0px 16px rgba(29, 185, 84, 0.5); */
}

.apple_music {
  /* background-color: rgba(251, 92, 116, 0.5);
  box-shadow: 0px 0px 16px rgba(251, 92, 116, 0.5); */
}

.youtube {
  /* background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 0px 16px rgba(255, 0, 0, 0.5); */
}

.audius {
  /* background-color: rgba(126, 27, 204, 0.5);
  box-shadow: 0px 0px 16px rgba(126, 27, 204, 0.5); */
}

.jukebox_radio {
  /* background-color: rgba(0, 71, 255, 0.5);
  box-shadow: 0px 0px 16px rgba(0, 71, 255, 0.5); */
}
