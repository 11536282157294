.app-main-container {
  /* sizing and position */
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;

  /* center things */
  display: flex;
  justify-content: center;
  align-items: center;
}

#apple-music-video-container > video {
  width: 100%;
}
