.QueueApp {
  height: 100%;
  padding-top: 50px;
  top: -50px;
  position: relative;
}

.NowPlaying {
  width: calc(100% - 4px);
  height: 84px;

  margin: 0;
  padding: 0;

  border: 2px solid #0047FF;
  border-radius: 44px;

  background-color: #FFF;

  font-size: 20px;
  outline: none;

  position: relative;

  font-family: "Raleway";
  font-weight: 500;

  z-index: 4;
}

.NowPlayingArt {
  width: 74px;
  height: 58px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  float: left;
  margin-top: 14px;
  margin-left: 46px;
}

.NowPlayingArt > img {
  max-height: 58px;
  max-width: 74px;
}

.NowPlayingInformation {
  margin-top: 18px;
  margin-left: 36px;
  display: inline-block;
  width: calc(100% - 267px);
}

.NowPlayingInformation > h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.NowPlayingInformation > h6 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  padding-top: 9px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.NowPlayingDuration {
  margin-top: 29px;
  margin-right: 37px;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;

  display: inline-block;
  cursor: default;
  float: right;
}

.QueueContainer {
  overflow-y: scroll;
  top: -10px;
  padding-top: 10px;
  position: relative;
  height: calc(100vh - 297px);
}

.QueueContainer::-webkit-scrollbar {
  display: none;
}

.PlaybackDuration {
  height: 48px;
  border-radius: 24px;
  padding: 0 32px;

  display: inline-block;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;

  margin-top: 22px;
  margin-bottom: 72px;
  margin-left: 24px;

  background-color: #DFE5F8;

  color: #000;
}

.AddToQueue {
  display: inline-block;
  float: right;

  margin-top: 22px;
  margin-bottom: 72px;
  margin-right: 24px;

  border: 2px solid #0047FF;
  box-sizing: border-box;
  border-radius: 24px;
  width: 174px;
  height: 48px;

  background-color: #FFF;

  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #0047FF;
  cursor: pointer;
}
