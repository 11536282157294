.MiniQueue {
  display: flex;
  flex-direction: column;

  padding: 40px;
  border-radius: 12px;

  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: #FFF;
  cursor: pointer;
}

.MiniQueue > h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-decoration: none;
  margin: 0;
  margin-bottom: 21px;
}

.MiniQueue > p {
  margin: 0;
}

.MiniQueue > ol {
  padding-left: 22px;
  margin: 0;
  display: block;
  position: relative;
  max-height: calc(100vh - 426px);
  overflow-y: hidden;
  padding-bottom: 21px;
}

.MiniQueue > ol::-webkit-scrollbar {
  display: none;
}

.MiniQueue > ol > li {
  padding-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
}

.MiniQueue > ol > li:last-child {
  padding-bottom: 0;
}
