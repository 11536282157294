.ParentProgressBar {
  height: 2px;
  padding: 18px 0;
  margin-top: -18px;
  position: relative;
}

.ProgressBar {
  display: block;
  width: 100%;
  position: absolute;
}

.ProgressPointer {
  position: absolute;
  display: block;
  height: 12px;
  width: 8px;
  top: -5px;
  cursor: pointer;
  z-index: 8;
  transition-property: left;
  transform-style: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: 0.15s;
}

.ProgressPointer > svg {
  margin-bottom: 5px;
}

.ProgressMarkerContainer {
  width: 100%;
  height: 16px;
  top: -16px;
  position: relative;
}
