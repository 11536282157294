.LoginContainer {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;
}

.Logo {
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  left: 43px;
  top: 38px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.LoginHeader {
  margin-bottom: 55px;
}

.LoginHeader > h3 {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 19px;
  margin-top: 0;
}

.LoginHeader > p {
  font-size: 18px;
  margin: 0;
}

.LoginHeader > p > a {
  text-decoration: none;
  font-weight: bold;
  color: #0047FF!important;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Login {
  background-color: #FFF;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);

  padding: 55px;
  border-radius: 55px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.FormBlock {
  margin-top: 30px;
  display: block;
}

.FormBlock:first-child {
  margin-top: 0px;
}

.FormBlock > span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.FormBlock > input {
  width: 360px;
  height: 68px;
  border: 2px solid #0047FF;
  caret-color: #0047FF;
  border-radius: 6px;
  margin-top: 14px;
  font-size: 20px;
  padding-left: 20px;
  display: inline;
  background-color: #FFF;
  outline: none;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.FormBlockInline {
  display: inline-block!important;
  margin-left: 12px;
  line-height: 25px;
  top: -6px;
  position: relative;
  cursor: pointer;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Checkbox {
  background: none;
  border: none;
  height: 25px;
  padding: 0;
  cursor: pointer;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Submit {
  width: 218px;
  height: 48px;
  border-radius: 24px;
  font-size: 20px;
  font-weight: bold;
  background-color: #0047FF;
  border: none;
  color: white;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 4px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.ForgotPassword {
  display: inline-block!important;
  margin-left: 30px;
  float: right;
  margin-top: 12px;
}

.ForgotPassword > a {
  text-decoration: none;
  font-weight: 500;
  color: #0047FF!important;
}
