.ProgressAll {
  display: inline-block;
  position: relative;
  float: left;
  height: 2px;
  background-color: #0047FF;
}

.ProgressStem_isolation {
  display: inline-block;
  position: relative;
  float: left;
  height: 2px;
  background-color: #ffb800;
}

.ProgressMuted {
  display: inline-block;
  position: relative;
  float: left;
  height: 2px;
  background-color: #bcbcbc;
}

.ProgressHoverContainerAll,
.ProgressHoverContainerMuted {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 18px;
  top: -2px;
  /* margin-left: calc(50% - 11px); */
}

.ProgressHoverPointerAll,
.ProgressHoverPointerMuted {
  margin-top: 4px;
  margin-left: calc(50% - 1px);
}

.ProgressHoverPointerMuted > svg > path:first-child {
  stroke: #BCBCBC;
}

.ProgressHoverPointerMuted > svg > path {
  fill: #BCBCBC;
}

.ProgressHoverPurpose {
  display: inline-block;
  float: left;
  margin-top: -17px;
  margin-left: 0px;
  padding-right: 10px;
  cursor: default;
}

.ProgressHoverDelete {
  background-color: #E9E9E9;
  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 4px;

  margin-top: -19px;
  cursor: pointer;

  display: inline-flex;
  float: left;
  align-items: center;
  justify-content: center;
}

.HoverContainer {
  position: absolute;
  display: block;
  width: max-content;
  margin-left: calc(50% + 16px);
}
