.SideBar {
  height: 100vh;
  width: 263px;
  position: relative;
  float: left;
  left: 0px;

  display: flex;
  align-items: center;
}

.Logo {
  position: absolute;
  width: 38px;
  height: 38px;
  left: 43px;
  top: 38px;
  z-index: 1064;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.MenuContainer {
  height: 573px;
  position: relative;
  display: flex;
  align-items: center;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.Menu {
  margin-left: 43px;
  padding-left: 0;
  height: 573px;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Menu > div {
  margin: 15px 0;
  border-radius: 50px;
  float: left;

  width: 141px;
  background-color: #DFE5F8;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.Menu > div > a {
  font-size: 20px;
  text-decoration: none;

  border-radius: 50px;
  padding: 5px 20px;
  display: block;
}

.LiSelected > a {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px!important;
  line-height: 35px;
  color: #0047FF!important;
  padding: 16px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
a:visited {
  text-decoration: none;
  color: black;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.BlockContainer {
  height: 213px;
  width: 24px;
  border-radius: 16px;

  background-color: #DFE5F8;

  position: absolute;
  left: 223px;
}

.Block {
  height: 30px;
  width: 8px;
  border-radius: 8px;

  background-color: #0047FF;
  left: 8px;

  position: relative;
}
